<template>
  <q-page class="accountPages">
    <template v-if="isLoggedIn">
      <div class="page-content favouriteItems wishlist-listing" v-if="loading">
        <div class="container">
          <div class="page-title hide-in-mobile q-mb-md">
            <div class="page-breadcrumb">
              <q-skeleton width="30px" height="20px" />
            </div>
            <q-skeleton width="250px" height="30px" />
          </div>
          <div class="row q-col-gutter-md">
            <div class="col-12 col-sm-6 col-md-4" v-for="n in 6" :key="n">
              <q-card class="my-card flex-center" style="height: 140px">
                <q-card-section>
                  <q-skeleton width="100%" height="20px" />
                  <q-skeleton width="50%" height="10px" class="q-mt-md" />
                </q-card-section>
                <q-card-actions>
                  <q-skeleton width="45%" height="40px" />

                  <q-skeleton class="q-ml-sm" width="45%" height="40px" />
                </q-card-actions>
              </q-card>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content favouriteItems wishlist-listing" v-else>
        <div class="container">
          <div class="page-title hide-in-mobile q-mb-md">
            <div class="page-breadcrumb">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
            </div>
            <h2>Manage Wishlist</h2>
          </div>
          <div class="row q-col-gutter-md">
            <div
              class="col-md-12"
              v-if="allWishlistName && allWishlistName.length >= 1"
            >
              <div class="manageWishlistItems">
                <div class="wishlistItem addWishlistItem">
                  <div class="wishlistInner">
                    <q-btn
                      unelevated
                      no-caps
                      flat
                      class="wishlistMiddle"
                      @click="showCreateWishlistDialog"
                    >
                      <SvgIcon size="16px" icon="plus" class="q-mr-sm" />Create
                      a new list
                    </q-btn>
                  </div>
                </div>
                <div
                  class="wishlistItem"
                  v-for="wishlist in allWishlistName"
                  :key="wishlist.wishListID"
                >
                  <div class="wishlistInner">
                    <div class="wishlistMiddle">
                      <div class="row q-col-gutter-md">
                        <div class="col">
                          <p
                            class="wishlistItem-wishlist"
                            v-if="wishlist.productCount == 0"
                          >
                            <router-link
                              class="text-black no-underline"
                              :to="{
                                name: 'Wishlist',
                                params: {
                                  wishListID: wishlist.wishListID,
                                },
                              }"
                            >
                              <div
                                class="text-h6 text-weight-bold text-capitalize word-break-word"
                              >
                                {{ wishlist.wishListName }}</div
                              >
                              <div class="text-body2 text-no-wrap">
                                No items added yet!</div
                              >
                            </router-link>
                          </p>
                          <p v-else>
                            <router-link
                              class="text-black no-underline"
                              :to="{
                                name: 'Wishlist',
                                params: {
                                  wishListID: wishlist.wishListID,
                                },
                              }"
                              ><div
                                class="text-h6 text-weight-bold text-capitalize word-break-word"
                                >{{ wishlist.wishListName }}
                              </div>

                              <div
                                class="text-body2 text-no-wrap"
                                v-text="
                                  `${wishlist.productCount} ${
                                    wishlist.productCount > 1 ? 'items' : 'item'
                                  } in this wishlist`
                                "
                              >
                              </div>
                            </router-link>
                          </p>
                        </div>
                        <div class="col-auto">
                          <router-link
                            class="no-underline"
                            :to="{
                              name: 'Wishlist',
                              params: {
                                wishListID: wishlist.wishListID,
                              },
                            }"
                          >
                            <q-img
                              :src="
                                wishlist.productImage
                                  ? wishlist.productImage
                                  : 'images/no-image-100.png'
                              "
                              width="72px"
                              height="72px"
                            ></q-img>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="wishlistBottom">
                      <q-btn
                        flat
                        class="edit"
                        color="blue"
                        @click="editWishlist(wishlist)"
                      >
                        <SvgIcon size="11px" icon="edit" class="q-mr-sm" />Edit
                      </q-btn>
                      <q-btn
                        flat
                        class="delete"
                        color="red"
                        style="border-left: 1px solid #ccc"
                        @click="deleteWishlist(wishlist)"
                      >
                        <SvgIcon size="11px" icon="trash" class="q-mr-sm" />
                        Delete
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <q-dialog
                v-model="manageAlert.show"
                :position="isMobile ? 'bottom' : 'standard'"
                :transition-show="isMobile ? 'slide-up' : 'jump-up'"
                :transition-hide="isMobile ? 'slide-down' : 'jump-down'"
              >
                <q-card class="removeCartItem">
                  <q-card-section class="row items-center q-pb-none">
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                  </q-card-section>

                  <q-card-section class="inner text-center">
                    <h5>Are you sure you want to remove this Wishlist?</h5>
                    <p
                      >If you remove this wishlist, then products that are
                      mapped into this wishlist will be deleted.</p
                    >
                    <div>
                      <q-btn
                        unelevated
                        rounded
                        outline
                        color="tertiary"
                        label="No"
                        class="q-ma-sm q-py-sm q-px-md radius-lg"
                        v-close-popup
                      />
                      <q-btn
                        unelevated
                        rounded
                        color="secondaryOnBody"
                        label="Yes"
                        class="q-ma-sm q-py-sm q-px-md radius-lg"
                        v-close-popup
                        @click="removeWishlist()"
                      />
                    </div>
                  </q-card-section>
                </q-card>
              </q-dialog>
            </div>
          </div>
        </div>
      </div>

      <EmptyComponent
        v-show="allWishlistName && allWishlistName.length == 0 && !loading"
        image="images/empty-wishlist.png"
      >
        <p>You don't have any wishlist.</p>
        <q-btn
          unelevated
          no-caps
          rounded
          outline
          padding="sm"
          color="tertiary"
          label="Create a new wishlist"
          @click="showCreateWishlistDialog"
          class="q-px-lg"
        />
      </EmptyComponent>
    </template>
    <EmptyComponent
      v-else
      image="images/empty-wishlist.png"
      btnText="Sign In"
      btnClass="q-pr-lg q-pl-lg q-mt-sm "
      :btnClick="
        () =>
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
      "
    >
      <p>Sign in or create an account to use your list.</p>
    </EmptyComponent>

    <CreateWishListDialog />
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { hideShowFullSpinner, mergeObjects } from 'src/utils'
import CreateWishListDialog from 'src/components/common/CreateWishlistDialog.vue'
import SvgIcon from 'src/components/SvgIcon.vue'

export default {
  name: 'PageWishlists',
  /* preFetch({ store }) {
    let promise = store.dispatch('wishlist/getAllWishlist')
    if (process.env.SERVER) return promise
  }, */
  components: {
    CreateWishListDialog,
    SvgIcon,
  },
  meta() {
    return {
      title: 'Wishlists',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      manageAlert: {
        show: false,
      },
      selectedWishlist: {},
    }
  },
  computed: {
    ...mapGetters('wishlist', ['allWishlistName', 'loading']),
    getWishlistItems() {
      return mergeObjects({ 'add-wishlist': {} }, this.allWishlistName)
    },
  },
  methods: {
    showCreateWishlistDialog(wishlist) {
      this.changeDialogState({
        dialog: 'CreateWishListDialog',
        val: true,
        properties: {
          wishlistInfo: wishlist ? wishlist : null,
          action: 'add',
        },
      })
    },
    deleteWishlist(wishlist) {
      this.manageAlert.show = true
      this.selectedWishlist = wishlist
    },
    async removeWishlist() {
      if (this.selectedWishlist) {
        hideShowFullSpinner(true)
        await this.$store
          .dispatch('wishlist/deleteWishlist', this.selectedWishlist.wishListID)
          .then((res) => {
            if (res && res.success) {
              this.showSuccess('Wishlist removed successfully.')
            }
          })
          .finally(() => {
            this.selectedWishlist = {}
            hideShowFullSpinner(false)
          })
      }
    },
    editWishlist(wishlist) {
      this.changeDialogState({
        dialog: 'CreateWishListDialog',
        val: true,
        properties: {
          wishlistInfo: wishlist ? wishlist : null,
          action: 'edit',
        },
      })
    },
  },
  mounted() {
    // prefetch code
    this.$store.dispatch('wishlist/getAllWishlist')
    // prefetch code
  },
}
</script>
<style lang="scss">
.manageWishlistItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -10px;
  margin-right: -10px;

  .wishlistItem {
    padding: 10px;
    flex: 0 0 100%;

    @media (min-width: 600px) {
      flex: 0 0 50%;
    }

    @media (min-width: 1024px) {
      flex: 0 0 33.33%;
    }

    .wishlistInner {
      display: flex;
      flex-direction: column;
      position: relative;
      border: 1px solid #cccccc;
      height: 100%;
      min-height: 150px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }

    .wishlistMiddle {
      padding: 20px;
      flex: 1 0 0;

      .q-img {
        box-shadow: 0px 0px 3px 0px #dddddd;
        border-radius: 7px;
      }

      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #314154;
        min-height: 21px;

        span {
          display: inline-block;
          vertical-align: middle;
          background: #91bf5b;
          margin-left: 5px;
          padding: 3px 15px;
          font-size: 10px;
          color: #fff;
          border-radius: 10px;
        }

        @media (min-width: 1681px) {
          font-size: 20px;
        }
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 13px;
        line-height: 1.3;
        color: #888888;

        @media (min-width: 1681px) {
          font-size: 16px;
        }
      }

      .toggle {
        margin-top: 15px;

        .q-toggle__label {
          font-size: 12px;
          color: #0f141a;
        }
      }
    }

    .quickAction {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 20px;
      color: #888888;
      background: #eeeeee;
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 50%;

      svg {
        width: 10px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
      }

      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    .wishlistSwitch {
      margin-top: 10px;
      min-height: 15px;
      font-size: 13px;
      color: #0f141a;
    }

    .wishlistBottom {
      border-top: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      a,
      button {
        flex: 1 0 50%;
        border-radius: 0;
      }

      a {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        border-left: 1px solid #ccc;

        @media (min-width: 1681px) {
          padding: 15px;
        }

        &:first-child {
          border-left: none;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        &.edit {
          color: $primaryOnBody;
          color: var(--q-color-primaryOnBody) !important;
        }

        &.disable {
          color: #888888;
          pointer-events: none;
        }
      }
    }

    &.addWishlistItem {
      .wishlistInner {
        text-align: center;
        background: #f2fbff;
        background: var(--q-color-light-primary);
        border-color: #99c2d2;
        border-color: var(--q-color-primary);

        @media (max-width: 767px) {
          min-height: 60px;
        }
      }

      .wishlistMiddle {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tertiary;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
</style>
