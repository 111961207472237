import { render, staticRenderFns } from "./Wishlists.vue?vue&type=template&id=5eec195c"
import script from "./Wishlists.vue?vue&type=script&lang=js"
export * from "./Wishlists.vue?vue&type=script&lang=js"
import style0 from "./Wishlists.vue?vue&type=style&index=0&id=5eec195c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QColor from 'quasar/src/components/color/QColor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSkeleton,QCard,QCardSection,QCardActions,QBtn,QImg,QDialog,QSpace,QToggle,QColor});qInstall(component, 'directives', {ClosePopup});
